import React, { useState, useContext } from 'react';
import './CSS/Signup.css';
import { BsPerson } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaPhoneAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import Navbar from '../components/Navbar';
import Footer from "../components/MyFooter";
import axios from 'axios';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import AuthContext from '../context/AuthContext';
import LoginButton from '../components/LoginButton';
import Swal from 'sweetalert2'; // Import SweetAlert
import { PhoneNumberUtil } from 'google-libphonenumber';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import parsePhoneNumberFromString from 'libphonenumber-js';
import config from '../config';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

function SignUp() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { registerUser } = useContext(AuthContext);
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [alreadyExistPhone, setAlreadyExistPhone] = useState(false)
  const [alreadyExistEmail, setAlreadyExistEmail] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({
    first_name: false,
    email: false,
    password: false,
    confirmPassword: false,
    username: false,
  });
  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (username) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(username));
    } catch (error) {
        return false;
    }
  };
  //const isValid = isPhoneValid(username);

  const validatePhoneNumber = (username) => {
    const phoneNumber = parsePhoneNumberFromString(username,'BD'); // Change 'US' to your preferred country code, or remove it for general validation

    if (phoneNumber && phoneNumber.isValid()) {
      return true;
    } else {
      return false;
    }
  };

  const isValid = validatePhoneNumber(username);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

const isAlreadyExistPhone = async(username)=>{
  try {
    const response = await axios.get(`${config.authUrl}check-username/?username=${username}`);
    setAlreadyExistPhone(response.data.username);
    return response.data.username;
} catch (error) {
    console.error('Error checking username:', error);
}
}
 
const isAlreadyExistEmail = async(email)=>{
  try {
    const response = await axios.get(`${config.authUrl}check-email/?email=${email}`);
    setAlreadyExistEmail(response.data.email);
    return response.data.username;

} catch (error) {
    console.error('Error checking username:', error);
}
}
  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };

  const routeChange = () => {
    let path = `./login`;
    navigate(path);
  };

  const validateField = (name, value) => {
    let errorMessage = '';
    switch(name) {
      case 'first_name':
        if (!value) errorMessage = 'Full Name is required';
        break;
      case 'username':
        if (!value) errorMessage = 'Phone number is required';
        else if (!/^\d+$/.test(value)) errorMessage = 'Phone number must be numeric';
        break;
      case 'email':
        if (!value) errorMessage = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(value)) errorMessage = 'Email format is invalid';
        break;
      case 'password':
        if (!value) errorMessage = 'Password is required';
        else if (value.length < 6) errorMessage = 'Password must be at least 6 characters';
        break;
      case 'confirmPassword':
        if (!value) errorMessage = 'Confirm Password is required';
        else if (value !== password) errorMessage = 'Passwords do not match';
        break;
      default:
        break;
    }
    return errorMessage;
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;

    // Update field values
    switch(field) {
      case 'first_name': setFirst_name(value); break;
      case 'username': setUsername(value); isAlreadyExistPhone(value); break;
      case 'email': setEmail(value); isAlreadyExistEmail(value); break;
      case 'password': setPassword(value); break;
      case 'confirmPassword': setConfirmPassword(value); break;
      default: break;
    }
  };

  const showAlert = (message) => {
    Swal.fire({
      title: 'Warning!',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Ok'
    });
  };

  const submitRegistration = async (e) => {
    e.preventDefault();
    const fields = [
      { name: 'first_name', value: first_name },
      { name: 'username', value: username },
      { name: 'email', value: email },
      { name: 'password', value: password },
      { name: 'confirmPassword', value: confirmPassword },
    ];

    setLoading(true);

    for (const field of fields) {
      const errorMessage = validateField(field.name, field.value);
      if (errorMessage) {
        showAlert(errorMessage);
        return; // Stop further validation
      }
    }

    // If validation passes
    try {
      const data = await registerUser(first_name,username , email, password,confirmPassword);
      setLoading(false);
      if (data) {
        Swal.fire({
          title: 'Success!',
          text: 'Registration successful! Please check your mail and activate the account.',
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/login');
            }
        })
        ;
      }
  } catch (error) {
      setLoading(false);
      Swal.fire({
          title: 'Warning!',
          text: 'Registration Failed. Something went wrong.',
          icon: 'warning',
      });
  }
    
  };

  return (
    <>
      <Navbar />
      <Form onSubmit={submitRegistration} method='POST' className='signup-container'>
        <div className='signup-header'>
          <div className='signup-text'>Sign Up</div>
          <div className='signup-underline'></div>
        </div>
        <div className='signup-inputs'>
          <Form.Group className='signup-input'>
            <BsPerson className='signup-img' />
            <Form.Control
              type='text'
              placeholder='Full Name'
              value={first_name}
              onChange={(e) => handleInputChange(e, 'first_name')}
              onBlur={() => handleBlur('first_name')}
            />
          </Form.Group>
          {touched.first_name && !first_name && (
            <div style={{ color: 'red' }}>Please enter your full name.</div>
          )}

          <Form.Group className='signup-input'>
            <FaPhoneAlt className='signup-img' />
            <Form.Control
              type='text'
              placeholder='Phone'
              value={username}
              onChange={(e) => handleInputChange(e, 'username')}
              onBlur={() => handleBlur('username')}
            />
          </Form.Group>
          {touched.username && !isValid && (
            <div style={{ color: 'red' }}>Please Enter a valid Phone No.</div>
          )}
{touched.username && alreadyExistPhone && (
            <div style={{ color: 'red' }}>Phone No. is already Registered</div>
          )}

          <Form.Group className='signup-input'>
            <HiOutlineMail className='signup-img' />
            <Form.Control
              type='email'
              placeholder='Email Id'
              value={email}
              onChange={(e) => handleInputChange(e, 'email')}
              required
              onBlur={() => handleBlur('email')}
            />
          </Form.Group>
          {touched.email && !isValidEmail(email) && (
            <div style={{ color: 'red' }}>Please enter a valid email.</div>
          )}
          {touched.email && alreadyExistEmail && (
            <div style={{ color: 'red' }}>Email is already Registered.</div>
          )}

          <Form.Group className='signup-input'>
            <RiLockPasswordLine className='signup-img' />
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder='Password'
              value={password}
              onChange={(e) => handleInputChange(e, 'password')}
              required
              onBlur={() => handleBlur('password')}
            />
            <span
              className="toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </Form.Group>
          {touched.password && password.length < 8 && (
            <div style={{ color: 'red' }}>
                Password must be 8 characters long.
            </div>
          )}

          <Form.Group className='signup-input'>
            <RiLockPasswordLine className='signup-img' />
            <Form.Control
              type={showConfirmPassword ? "text" : "password"}
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={(e) => handleInputChange(e, 'confirmPassword')}
              onBlur={() => handleBlur('confirmPassword')}
            />
            <span
              className="toggle-password"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </Form.Group>
          {touched.confirmPassword && password !== confirmPassword && (
              <div style={{ color: 'red' }}>Passwords don't match</div>
          )}

          <div className='signup-forgot-password'>
            Already registered? <span onClick={routeChange}>Please Login</span>
          </div>
          <LoginButton/>
          <div className='signup-submit-container'>
            <Button type='submit' disabled={alreadyExistEmail} className='signup-submit'>{loading ? 'Loading...' : 'SIGN UP'}</Button>
          </div>
        </div>
      </Form>
      
      <Footer />
    </>
  )
}

export default SignUp;

import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from "../components/MyFooter";
import AuthContext from '../context/AuthContext';
import { FaPhoneAlt, FaEye,FaEyeSlash } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import './CSS/Signup.css';
import LoginButton from '../components/LoginButton';

function Login() {
  const navigate = useNavigate();
  const { loginUser } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .matches(/^[0-9]{11}$/, 'Phone number must be 11 digits.')
      .required('Phone number is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required')
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      console.log("login credentials ",data)
    const response =  await loginUser(data.username, data.password);
    setLoading(false);
     if(response){
      navigate('/userdashboard');
     }
    } catch (error) {
      setLoading(false)
      console.error("Login failed:", error);
      // alert("Login failed. Please check your credentials.");
    }
  };

  const routeChange1 = () => {
    navigate('/signup');
  };

  return (
    <>
      <Navbar />
      <form onSubmit={handleSubmit(onSubmit)} className="signup-container">
        <div className="signup-header">
          <div className="signup-text">Log In</div>
          <div className="signup-underline"></div>
        </div>
        <div className="signup-inputs">
          <div className="signup-input">
            <FaPhoneAlt className="signup-img" />
            <input
              type="text"
              placeholder="Phone No."
              {...register('username')}
            />
            
          </div>
          {errors.username && (
              <p className="error-message">{errors.username.message}</p>
            )}

          <div className="signup-input">
            <RiLockPasswordLine className="signup-img" />
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...register('password')}
            />
            <span
              className="toggle-password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
            
          </div>
          {errors.password && (
              <p className="error-message">{errors.password.message}</p>
            )}
          <div className="signup-forgot-password">
            Forgot Password? <Link to={'../forget_pass'}>
            <span>Click Here</span>
            </Link> 
          </div>
          <div className="signup-forgot-password">
            Not Registered? <span onClick={routeChange1}>Sign Up</span>
          </div>
          <LoginButton/>
          <div className="signup-submit-container">
            <button className="signup-submit" type="submit" disabled={isSubmitting}>
              {loading ? 'Loading...' : 'LOGIN'}
            </button>
          </div>
        </div>
      </form>
      <Footer />
    </>
  );
}

export default Login;

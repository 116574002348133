import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import Footer from "../components/MyFooter";
import heroImage from "../assets/middle_left_box.png"
import './Blog.css'
import blogImage from '../assets/blog_image.png'
import MiddleComponent from "./MiddleComponent";
const Blog = () => {
  const blogs = [
    {
      id: 1,
      title: "The Importance of Education",
      description:
        "Education is a powerful tool that can unlock many doors. In this blog, we explore why education is crucial for personal and professional growth.",
      image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=400&h=200", // Replace with actual image URLs
    },
    {
      id: 2,
      title: "How Scholarships Can Change Lives",
      description:
        "Scholarships provide financial assistance to deserving students. Here’s how they can positively impact students from different backgrounds.",
      image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=400&h=200",
    },
    {
      id: 3,
      title: "Tips for Academic Success",
      description:
        "Discover the best strategies for excelling in your studies, from effective time management to proper study techniques.",
      image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=400&h=200",
    },
  ];

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true); // Trigger the animation when the component mounts
  }, []);

  const title = "এইচএসসি-২৪ ক্রাশ কোর্স";
  const subtitle = "পদার্থ, রসায়ন, গণিত, জীববিজ্ঞান";
  const duration = "সময়সীমা- ৩ মাস (ডিসেম্বর'২৩-ফেব্রুয়ারি'২৪)";
  const features = [
    "ভিডিও ক্লাস: ১০০টি",
    "অনলাইন পরীক্ষা",
    "কুইজ",
    "ফ্রি লেকচার শীট",
    "সাপোর্ট সেশন",
    "ডিসকাশন মেন্টর সাপোর্ট",
  ];
  const courseFee = "কোর্স ফি ৫০০০৳";
  const buttonText = "ভর্তি হোন";

  return (
    <>
    <Navbar/>
    <Container>
    <MiddleComponent/>
    <BlogContainer>
      <PageHeader>
        <Title>Our Blog</Title>
        <Subtitle>Stay updated with our latest news and articles</Subtitle>
      </PageHeader>

      <BlogList>
        {blogs.map((blog) => (
          <BlogCard key={blog.id}>
            <BlogImage src={blogImage} alt={blog.title} />
            <BlogContent>
              <BlogTitle>{blog.title}</BlogTitle>
              <BlogDescription>{blog.description}</BlogDescription>
              <ReadMoreButton>Read More</ReadMoreButton>
            </BlogContent>
          </BlogCard>
        ))}
      </BlogList>
    </BlogContainer>
    </Container>
    <Footer/>
    </>
  );
};

// Styled Components
const Container = styled.div`
  margin: 0 auto;
`;

const BlogContainer = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 60px auto;
  padding: 20px;
`;

const PageHeader = styled.header`
  text-align: center;
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2F88FF;
  margin-top: 15px;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #7f8c8d;
`;

const BlogList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
`;

const BlogCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  flex: 1;
  min-width: 280px;
`;

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const BlogContent = styled.div`
  padding: 20px;
`;

const BlogTitle = styled.h2`
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
`;

const BlogDescription = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 20px;
`;

const ReadMoreButton = styled.button`
  padding: 10px 20px;
  background-color: #2F88FF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #CB711E;
  }
`;

export default Blog;

import Footer from "../components/MyFooter";
import Navbar from "../components/Navbar";
import React, { useState } from "react";
import styled from "styled-components";

const Scholarship = () => {
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the form submission (file, image, other info)
    alert("Form submitted successfully!");
  };

  return (
    <>
    <Navbar/>
    <Container>
    <FormContainer>
      <FormTitle>Student Information Form</FormTitle>
      <Form onSubmit={handleSubmit}>
        <InputField type="text" placeholder="Full Name" required />
        <InputField type="email" placeholder="Email" required />
        <InputField type="text" placeholder="Phone" required />
        <InputField type="text" placeholder="Class" required />
        <InputField type="text" placeholder="Address" required />
        <Textarea placeholder="Other Considerations"></Textarea>
        <FileUpload>
          <label>Upload Related File:</label>
          <FileInput type="file" onChange={handleFileChange} required />
        </FileUpload>
        <FileUpload>
          <label>Upload Related Image:</label>
          <FileInput type="file" accept="image/*" onChange={handleImageChange} required />
        </FileUpload>
        <SubmitButton type="submit">Submit</SubmitButton>
      </Form>
    </FormContainer>
    </Container>
    <Footer/>
    </>
  );
};

// Styled Components
// Styled Components
const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
`;

const FormContainer = styled.div`
  width: 60%;
  margin: 100px auto;
  padding: 20px;
  background-color: #b03360;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  color: white;
`;

const FormTitle = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: white;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const InputField = styled.input`
  padding: 12px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  label {
    font-size: 1rem;
    margin-bottom: 5px;
  }
`;

const FileInput = styled.input`
  padding: 8px;
  border: none;
  border-radius: 5px;
`;

const Textarea = styled.textarea`
  padding: 12px;
  font-size: 1rem;
  border: 2px solid #bdc3c7;
  border-radius: 5px;
  min-height: 100px;
`;

const SubmitButton = styled.button`
  padding: 12px;
  background-color: #58C233;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e67e22;
  }
`;

export default Scholarship;

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
// import login_page_ayat from '../assets/login_page_ayat.png';
// import login_page_ayat_meaning from '../assets/login_page_ayat_meaning.png';
import './activate.css';

import config from '../config';

const bgColorChange = keyframes`
  0% { background-color: #12B48D; }
  100% { background-color: #12B48D; }
`;
// background: linear-gradient(135deg, #72EDF2 10%, #6ae551 100%);
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: ${bgColorChange} 10s infinite;
  text-align: center;
  padding: 20px;
  
  background: #12B48D;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
`;

const AnimatedText = styled(motion.h1)`
  font-size: 2em;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const LogInButton = styled(motion.button)`
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  color: #12B48D;
  outline: none;
  margin-top: 20px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #12B48D;
    color: #ffffff;
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 8px 16px;
  }
`;

const textVariants = {
  animate: {
    y: [0, -20, 0], // Animate text vertically
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse"
    }
  }
};

const Activate = () => {
  const { uid, token } = useParams();  // Extract uid and token from the URL
  const [verified, setVerified] = useState(false);  // Tracks if the account is verified
  const [error, setError] = useState(null);  // Tracks if there is any error
  const API_URL = config.authUrl;

  // Automatically verify the account when the component mounts
  useEffect(() => {
    const verifyAccount = async () => {
      try {
        const response = await axios.get(`${API_URL}activate/${uid}/${token}/`);
        console.log("response is ",response)
        if (response.status === 200) {
          setVerified(true);  // Set verified to true if the response is successful
        }
      } catch (error) {
        setError('Activation failed. Please try again or contact support.');  // Set error if verification fails
      }
    };

    verifyAccount();  // Call the verification function
  }, [API_URL, uid, token]);

  return (
    <PageContainer>
      <ContentContainer>
        
        <div>
          {verified ? (
            <AnimatedText>Your profile is verified! You can now 
              <Link to='/'>
                <LogInButton
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Log In
                </LogInButton>
              </Link>
            </AnimatedText>
          ) : error ? (
            <AnimatedText>{error}</AnimatedText>  // Show error message if there's a problem
          ) : (
            <AnimatedText variants={textVariants} animate="animate">
              Verifying your account, please wait...
            </AnimatedText>  // Show loading text while verification is in progress
          )}
        </div>
      </ContentContainer>
    </PageContainer>
  );
};

export default Activate;
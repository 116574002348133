import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import { fetchUserData } from '../services/userService';

const API_URL = config.authUrl;
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() => {
        const storedTokens = localStorage.getItem('authTokens');
        return storedTokens ? JSON.parse(storedTokens) : null;
    });
    const csrfToken = Cookies.get('csrftoken');

    const [user, setUser] = useState(() => {
        const storedTokens = localStorage.getItem('authTokens');
        return storedTokens ? jwtDecode(JSON.parse(storedTokens).access) : null;
    });
    const [userInfo,setUserInfo] = useState([])
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
   
    
  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoading(true);
        const data = await fetchUserData(user?.user_id, authTokens?.access);
        setUserInfo(data);
        // setError(null);
      } catch (err) {
        // setError('Failed to load user data');
        console.log("Failed to load user data")
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, [user?.user_id, authTokens?.access]);

    // Login function
    const loginUser = async (username, password) => {
        try {
            console.log("Attempting login for user:", username);

            // Make POST request to the login endpoint
            const response = await axios.post(`${API_URL}token/`, { username, password });

            // Extract token data
            const { access, refresh } = response.data;

            // Set authentication tokens and user data
            setAuthTokens({ access, refresh });
            setUser(jwtDecode(access));

            // Store tokens in localStorage
            localStorage.setItem('authTokens', JSON.stringify({ access, refresh }));

            // Display success alert
            Swal.fire({
                title: 'Success!',
                text: 'Login successful! Welcome.',
                icon: 'success',
                confirmButtonText: 'Ok',
            });

            console.log("Login successful");
            return response.data;

        } catch (error) {
            // Handle errors
            const errorMessage = error.response?.data?.alert || 'Login failed. Please try again.';

            console.error("Login error:", errorMessage);

            // Display error alert
            Swal.fire({
                title: 'Warning!',
                text: errorMessage,
                icon: 'warning',
            });

            throw new Error(errorMessage);
        }
    };

    const registerUser = async (first_name, username, email, password, password2) => {
        setErrors({});
        console.log("all data", first_name, username, email, password, password2);
        try {
            const response = await axios.post(API_URL + 'register/', { first_name, username, email, password, password2 });
            if (response.status === 201) {
                
                return response.data;
            }

        } catch (error) {
            if (error.response && error.response.data) {
                setErrors(error.response.data);
                console.log("error", error)
            }
            // alert("Registration Failed: An error occurred during registration.");
            Swal.fire({
                title: 'Error!',
                text: error.response?.data?.message || 'Registration failed. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }
    };

    const logoutUser = async () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');


        Cookies.remove('sessionid')
        Cookies.remove('csrftoken')
        console.log("worked! logout")
    };

    const contextData = {
        user,
        setUser,
        authTokens,
        setAuthTokens,
        userInfo,
        setUserInfo,
        registerUser,
        loginUser,
        logoutUser,
        errors,


    };

    return (
        <AuthContext.Provider value={contextData}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
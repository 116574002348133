import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React from 'react'
import CourseImage1 from '../../assets/course_image.png'
// import { blue } from '@mui/material/colors';
import { Link, useLocation, useNavigate } from "react-router-dom";
import '../CSS/courseCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBangladeshiTakaSign } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';



const CourseCard = ({ course, userInfo }) => {

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `./enroll`;
        navigate(path);
    }
    const coursedetails = (id) => {
        let path1 = `./${id}`;
        navigate(path1);
    }
    return (
        <Box className='course-card-box'>

            <Card elevation={3} >
                <Link to={`../course/${course?.courseId}/`}>

                    <CardMedia
                        component='img'
                        height={140}
                        image={`${config.authUrl}${course?.bannerImg}`}
                        alt='Course'
                        className='course-card-image'
                    />
                    <CardContent className='course-card-content'>
                        <Typography gutterBottom variant="h5" component='div' color='#1556FC' className='course-card-content-title'>
                            {course?.courseTitle}

                        </Typography>
                        <Typography variant="body2" color='black' className='course-card-content-topiclist'>
                            {course?.topicList}
                        </Typography>
                    </CardContent>
                </Link>

                <CardActions className='course-card-content'>
                    <div className='course-card-review'>
                        <div1>
                            <div11>{course?.classNumber} class</div11>
                            <div12>|</div12>
                            <div13>{course?.courseDuration} Months</div13>
                        </div1>
                        <div2>
                            {course?.courseRating}
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            (245)
                        </div2>
                    </div>
                    {/* <Button size='small'>Share</Button> */}
                    <div className='course-card-footer-content'>
                        <div1>
                            Course Fee
                        </div1>
                        <div4>
                            <div2>
                                {course?.courseFee}
                                <FontAwesomeIcon icon={faBangladeshiTakaSign} />
                            </div2>
                            <div3>
                                {course?.courseFee - course.courseDiscount}
                                <FontAwesomeIcon icon={faBangladeshiTakaSign} />
                            </div3>
                        </div4>
                    </div>
                    {
                        userInfo?.enrolledCourses?.includes(course?.courseId) ? <Link to={`../coursedetailsuser/${course?.courseId}/`} state={{ course: course }}>
                            <div className='play-button'>
                                Play Course
                            </div>
                        </Link> : <Link to={'../coursecheckout'} state={{ course: course }}>
                            <div className='enroll-button'>
                                Enroll
                            </div>
                        </Link>
                    }


                </CardActions>
            </Card>
        </Box>
    )
}

export default CourseCard
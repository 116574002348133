import React,{useState,useEffect} from 'react'
import CourseCard from '../Course/CourseCard'
import { Grid } from '@mui/material'
import '../CSS/enrolledcourses.css'
import EnrolledCourseCard from './EnrolledCourseCard'
import { fetchUserData } from '../../services/userService';


const EnrolledCourses = ({enrolledCourses,user,authTokens}) => {

  const [enrolledCoursesId, setEnrolledCoursesId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = authTokens?.access;
  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoading(true);
        const data = await fetchUserData(user?.user_id, token);
        setEnrolledCoursesId(data?.enrolledCourses);
        setError(null);
      } catch (err) {
        setError('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, [user?.user_id, token]);

  console.log("user data",enrolledCoursesId)
  return (
    enrolledCourses &&
    <div className='enrolled-courses-main'>
    <div className='enrolled-courses-headline'>Your Enrolled Courses</div>
    
    <Grid container className='enrolled-courses-course-card'>
      {
        enrolledCoursesId?.map((enrolledId)=>(
          <EnrolledCourseCard id={enrolledId} token={token}/>
        ))
      }
        
        {/* <EnrolledCourseCard/>
        <EnrolledCourseCard/>
        <EnrolledCourseCard/>
        <EnrolledCourseCard/>
        <EnrolledCourseCard/>
        <EnrolledCourseCard/>
        <EnrolledCourseCard/> */}
    </Grid>
    </div>
    
  )
}

export default EnrolledCourses
import GoogleButton from "react-google-button";
import config from "../config";
const onGoogleLoginSuccess = () => {
  const AUTH_URL = config.authUrl

    const GOOGLE_AUTH_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
    const REDIRECT_URI = 'auth/api/login/google/';
    const BASE_APP_URL = config.apiUrl;
    const BASE_API_URL = AUTH_URL;
    const GOOGLE_OAUTH2_CLIENT_ID = "985147780700-q4a328ut5bh09ftl1dpluu7vd29erlkb.apps.googleusercontent.com"

    const scope = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');
console.log("redirect_uri",BASE_API_URL,REDIRECT_URI)
    const params = {
        response_type: 'code',
        client_id: GOOGLE_OAUTH2_CLIENT_ID,
        redirect_uri: `${BASE_API_URL}${REDIRECT_URI}`,
        prompt: 'select_account',
        access_type: 'offline',
        scope
    };

    const urlParams = new URLSearchParams(params).toString();
    window.location = `${GOOGLE_AUTH_URL}?${urlParams}`;
};

const LoginButton = () => {
    return <GoogleButton onClick={onGoogleLoginSuccess} label="Sign in with Google" />
}

export default LoginButton
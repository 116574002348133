import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Footer from '../../components/MyFooter';
import config from '../../config';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const client = axios.create({
  baseURL: `${config.authUrl}`,
  withCredentials: true,  // Include credentials in requests
  headers: {
    'Content-Type': 'application/json',
  }
});
const BlogDetail = () => {
    const { id } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchBlog = async () => {
        try {
          const response = await client.get(`${config.apiUrl}blog/${id}`);
          setBlog(response.data);
          // console.log(response.data)
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchBlog();
    }, [id]);
  
    if (loading) return <p className="text-center text-lg">Loading...</p>;
    if (error) return <p className="text-center text-lg text-red-500">Error: {error}</p>;
  
    if (!blog) return <p className="text-center text-lg">Blog not found</p>;
  
    return (
        <>
        <Navbar/> 

      <div className="bg-gray-100 min-h-screen p-4 pt-14">
        <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg shadow-lg transition-all duration-500 transform hover:scale-105">
          <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
          <p className="text-gray-700 mb-4">{blog.content}</p>
          <div className="text-sm text-gray-500">
            <p>Created At: {new Date(blog.createdAt).toLocaleString()}</p>
            <p>Updated At: {new Date(blog.updatedAt).toLocaleString()}</p>
          </div>
        </div>
      </div>
      <Footer/>
      </>
    );
  };
  
  export default BlogDetail;
  
import config from "../config";
import axios from 'axios';

export const fetchUserData = async (userId, token) => {
  // ...function logic
  // console.log("authTokens", token)
  const response = await fetch(`${config.apiUrl}userInfo/${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
  if (!response.ok) throw new Error('Failed to fetch user data');
  return response.json();
};


export const updateUserData = async (userId, formData, token) => {
  console.log("forma data",formData)
  return axios.put(`${config.apiUrl}userInfo/${userId}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });
};

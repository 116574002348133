import React, { useState } from "react";
import styled from "styled-components";
import { FaStar } from "react-icons/fa";
import Navbar from "../components/Navbar";
import Footer from "../components/MyFooter";
import CourseCard from "./Course/CourseCard";

const FreeCourse = () => {
  const [filters, setFilters] = useState({
    categories: {
      "Web Development": false,
      "Data Science": false,
      "Design": false,
    },
    pricing: {
      Free: false,
      Premium: false,
      Scholarship: false,
    },
  });

  const handleCheckboxChange = (e, type) => {
    const { name, checked } = e.target;
    setFilters((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [name]: checked,
      },
    }));
  };

  const courses = [
    {
      id: 1,
      title: "Complete React Developer Course",
      subtitle: "Learn to build React apps from scratch",
      instructor: "John Doe",
      rating: 4.7,
      totalRatings: 1345,
      price: "$49.99",
      discount: "$19.99",
      numClasses: 30,
      duration: "20 hours",
      image: "https://images.unsplash.com/photo-1584697964198-3c213d1122fa?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=400&h=200",
    },
    {
      id: 2,
      title: "Advanced Python Programming",
      subtitle: "Master Python with real-world projects",
      instructor: "Jane Smith",
      rating: 4.8,
      totalRatings: 876,
      price: "$59.99",
      discount: "$24.99",
      numClasses: 40,
      duration: "30 hours",
      image: "https://images.unsplash.com/photo-1503676260728-1c00da094a0b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=400&h=200",
    },
  ];

  return (
    <>
      <Navbar />
      <Container>
        <PageContainer>
          <FilterColumn>
            <FilterCard>
              <FilterTitle>Course Category</FilterTitle>
              {Object.keys(filters.categories).map((category) => (
                <CheckboxLabel key={category}>
                  <Checkbox
                    type="checkbox"
                    name={category}
                    checked={filters.categories[category]}
                    onChange={(e) => handleCheckboxChange(e, "categories")}
                  />
                  {category}
                </CheckboxLabel>
              ))}
            </FilterCard>

            <FilterCard>
              <FilterTitle>Pricing</FilterTitle>
              {Object.keys(filters.pricing).map((price) => (
                <CheckboxLabel key={price}>
                  <Checkbox
                    type="checkbox"
                    name={price}
                    checked={filters.pricing[price]}
                    onChange={(e) => handleCheckboxChange(e, "pricing")}
                  />
                  {price}
                </CheckboxLabel>
              ))}
            </FilterCard>
          </FilterColumn>

          <CourseList>
            {courses.map((course) => (
                <CourseCard course={course}/>
            ))}
          </CourseList>
        </PageContainer>
      </Container>
      <Footer />
    </>
  );
};

// Styled Components
const Container = styled.div`
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 80px auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FilterColumn = styled.div`
  width: 250px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const FilterCard = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const FilterTitle = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const CourseList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  gap: 20px; /* Gap between course cards */
  flex-grow: 1;
  margin-left: 20px; /* Adds space between filters and course cards */

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* One column for small screens */
  }
`;

// const CourseCard = styled.div`
//   background-color: white;
//   border-radius: 10px;
//   overflow: hidden;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
//   display: flex;
//   flex-direction: column;
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: translateY(-5px);
//   }
// `;

const CourseImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CourseInfo = styled.div`
  padding: 20px;
`;

const CourseTitle = styled.h2`
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
`;

const CourseSubtitle = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const CourseDetails = styled.div`
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const Rating = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #f39c12;
`;

const RatingText = styled.span`
  margin-left: 5px; /* Space between star and text */
  white-space: nowrap; /* Prevent wrapping */
`;

const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const DiscountedPrice = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  color: #e74c3c;
`;

const OriginalPrice = styled.span`
  font-size: 1rem;
  color: #7f8c8d;
  text-decoration: line-through;
`;

const EnrollButton = styled.button`
  background-color: #2ecc71;
  color: white;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #27ae60;
  }
`;

export default FreeCourse;

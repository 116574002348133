import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';
import config from '../../config';
import '../CSS/sidebarfilter.css';

const CourseLeftBar = ({ onFilterChange, openFilterbarToggle, OpenSideFilterbar }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPricing, setSelectedPricing] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}courseCategory/`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryChange = (event, categoryId) => {
    const updatedCategories = event.target.checked
      ? [...selectedCategories, categoryId]
      : selectedCategories.filter(id => id !== categoryId);

    setSelectedCategories(updatedCategories);
    onFilterChange(updatedCategories, selectedPricing);
  };

  const handlePricingChange = (event, pricingValue) => {
    const updatedPricing = event.target.checked
      ? [...selectedPricing, pricingValue]
      : selectedPricing.filter(value => value !== pricingValue);

    setSelectedPricing(updatedPricing);
    onFilterChange(selectedCategories, updatedPricing);
  };

  return (
    <Grid
      className={`course-leftbar ${openFilterbarToggle ? 'block bg-dark' : 'hidden'} md:block`}
      sx={{
        display: { xs: openFilterbarToggle ? 'block' : 'none', md: 'block' },
        backgroundColor: openFilterbarToggle ? '#263043' : 'transparent', // MUI inline styling for bg color
      }}
    >
      <div className='sidebar-title'>
        <span className='userdashboard-icon userdashboard-close_icon' onClick={OpenSideFilterbar}>X</span>
      </div>

      <Grid item xs={12} sm={6} md={12}>
        {/* Course Pricing Filter */}
        <FormControl sx={{ m: 3 }} className='course-left-bar-form-control1'>
          <FormLabel className="course-left-box1-header">Course Pricing</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={(event) => handlePricingChange(event, 0)} />}
              label="Free Course"
            />
            <FormControlLabel
              control={<Checkbox onChange={(event) => handlePricingChange(event, 1)} />}
              label="Paid Course"
            />
            <FormControlLabel
              control={<Checkbox onChange={(event) => handlePricingChange(event, 2)} />}
              label="Scholarship"
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} md={12}>
        {/* Course Category Filter */}
        <FormControl sx={{ m: 3 }} className='course-left-bar-form-control2'>
          <FormLabel className="course-left-box1-header">Course Category</FormLabel>
          <FormGroup className='course-left-bar-formgroup'>
            {categories.length > 0 ? (
              categories.map((category) => (
                <FormControlLabel
                  key={category.id}
                  control={
                    <Checkbox
                      onChange={(event) => handleCategoryChange(event, category.id)}
                      checked={selectedCategories.includes(category.id)}
                    />
                  }
                  label={category.name}
                />
              ))
            ) : (
              <p>Loading categories...</p>
            )}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CourseLeftBar;

import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material'
import React,{useState,useEffect} from 'react'
import CourseImage1 from '../../assets/course_image.png'
// import { blue } from '@mui/material/colors';
import { Link, useNavigate } from "react-router-dom";
import './enrolled-course-card.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBangladeshiTakaSign } from '@fortawesome/free-solid-svg-icons';
import { fetchCourse } from '../../services/courseService';
import config from '../../config';
import axios from 'axios';

const EnrolledCourseCard = ({id,token}) => {
    const [course, setCourse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    useEffect(() => {
        const fetchCourses = async ()=>{
          try {
              const response = await axios.get(`${config.apiUrl}course/${id}`)
              setCourse(response.data)
          } catch (error) {
              setError(error.message)
          }
          finally{
              setLoading(false)
          }
        }
        fetchCourses();
      
      }, [id])

  return (
    <Box className='course-card-box'>

            <Card elevation={3} >
                <Link to={`${course?.courseId}/`}>

                    <CardMedia
                        component='img'
                        height={140}
                        image={`${course?.bannerImg}`}
                        alt='Course'
                    />
                    <CardContent className='course-card-content'>
                        <Typography gutterBottom variant="h5" component='div' color='#1556FC'>
                            {course?.courseTitle}

                        </Typography>
                        <Typography variant="body2" color='black'>
                            {course?.topicList}
                        </Typography>
                    </CardContent>
                </Link>

                <CardActions className='course-card-content'>
                    <divreview className='course-card-review'>
                        <div1>
                            <div11>{course?.classNumber} class</div11>
                            <div12>|</div12>
                            <div13>{course?.courseDuration} Months</div13>
                        </div1>
                        <div2>
                            {course?.courseRating}
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            <div21>*</div21>
                            (245)
                        </div2>
                    </divreview>
                    {/* <Button size='small'>Share</Button> */}
                    <div className='course-card-footer-content'>
                        <div1>
                            Course Fee
                        </div1>
                        <div4>
                            <div2>
                                {course?.courseFee}
                                <FontAwesomeIcon icon={faBangladeshiTakaSign} />
                            </div2>
                            <div3>
                                {course?.courseFee - course?.courseDiscount} -
                                <FontAwesomeIcon icon={faBangladeshiTakaSign} />
                            </div3>
                        </div4>
                    </div>
                    <div  className='enroll-button'>Enroll</div>

                </CardActions>
            </Card>
        </Box>
  )
}

export default EnrolledCourseCard
import * as React from 'react';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import '../CSS/course-details-accordion.css'
import MuiAccordion from '@mui/material/Accordion';
import { useState,useRef} from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  

const CourseDetailsAccordion = ({course}) => {
    const [expanded, setExpanded] = useState('panel1');
    const modules = course.modules;

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    let navigate = useNavigate(); 
    const routeChange = () =>{ 
        let path = `./coursedetailsuser`; 
        navigate(path);
    }

    // const videoPlayerRef = useRef(null);
    // const handlePlay = () =>{
    //     videoPlayerRef.current.play();
    // }

    return (
        <>
        <div className='course-details-content-headline'>
            Course Content
        </div>
        {modules
            .sort((a, b) => a.modulePosition - b.modulePosition)
            .map((module) => {
                return <Accordion defaultExpanded  key={module.modulePosition} className='course-details-module-accordion' expanded={expanded === `panel${module.modulePosition}`} onChange={handleChange(`panel${module.modulePosition}`)}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            
            >
            <AutoStoriesIcon className='course-details-accordion-icon'/>
            <Typography>M {module.modulePosition} :{module.moduleTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
            {/* <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography> */}
            {module.contents
                        .sort((a,b)=>a.contentPosition - b.contentPosition)
                        .map((content) => {
                           return <ListItem disablePadding>
                <ListItemButton >
                    <ListItemIcon>
                        <PlayCircleFilledIcon className='course-details-playcirclefield-icon'/>
                    </ListItemIcon>
                    <ListItemText primary={`L  ${content.contentPosition} : ${content.title}`}/>
                    {(module.modulePosition==1 && content.contentPosition ==1) ? <div className='course-details-free-video'>Free Video</div>: <div className='course-details-free-video-lock'>
                        <LockIcon/>
                    </div>}
                                
                </ListItemButton>
            </ListItem>
            })}
            
            </AccordionDetails>
        </Accordion>
            })}

        
        </>
    );

}

export default CourseDetailsAccordion
export const AcademicOptions = [
    {
        title : "SSC",
        url : "/ssc",
        cName : "academic-links",
        //icon : "fa-sharp fa-solid fa-house"
    },
    {
        title : "HSC",
        url : "/hsc",
        cName : "academic-links",
        //icon : "fa-sharp fa-solid fa-circle-info"
    },
    {
        title : "Others",
        url : "/others",
        cName : "academic-links",
        //icon : "fa-sharp fa-solid fa-briefcase"
    }
]